import { CommonComponents } from "../CommonComponents"
import { Footer } from "../Footer"
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from "react";
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MatchCard from "./MatchCard";
import { MatchHighlightModal } from "./MatchHighlightModal";


export const MatchesIndex = () => {
  const [modalShow, setModalShow] = useState(false);

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (index, item) => {
    console.log(`Slide changed to: ${index}`, item);
  };

  const onClickItem = (index, item) => {
    console.log(`Item clicked: ${index}`, item);
  };

  const onClickThumb = (index, item) => {
    console.log(`Thumbnail clicked: ${index}`, item);
  };


  return <>
    <main>
      <CommonComponents />
      <>
        <Carousel
          showArrows={false}
          showThumbs={false}
          autoPlay={true}
          onChange={onChange}
          onClickItem={onClickItem}
          onClickThumb={onClickThumb}
        >
          <div>
            <img src="https://t4.ftcdn.net/jpg/02/71/74/27/360_F_271742787_fvFutvDou1ryxWmkrbJuheoMjSuthzqD.jpg" alt="Legend 1" />
            <p className="legend">Legend 1</p>
          </div>
          <div>
            <img src="https://t4.ftcdn.net/jpg/02/71/74/27/360_F_271742787_fvFutvDou1ryxWmkrbJuheoMjSuthzqD.jpg" alt="Legend 2" />
            <p className="legend">Legend 2</p>
          </div>
          <div>
            <img src="https://t4.ftcdn.net/jpg/02/71/74/27/360_F_271742787_fvFutvDou1ryxWmkrbJuheoMjSuthzqD.jpg" alt="Legend 3" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src="https://t4.ftcdn.net/jpg/02/71/74/27/360_F_271742787_fvFutvDou1ryxWmkrbJuheoMjSuthzqD.jpg" alt="Legend 4" />
            <p className="legend">Legend 4</p>
          </div>
          <div>
            <img src="https://t4.ftcdn.net/jpg/02/71/74/27/360_F_271742787_fvFutvDou1ryxWmkrbJuheoMjSuthzqD.jpg" alt="Legend 5" />
            <p className="legend">Legend 5</p>
          </div>
          <div>
            <img src="https://t4.ftcdn.net/jpg/02/71/74/27/360_F_271742787_fvFutvDou1ryxWmkrbJuheoMjSuthzqD.jpg" alt="Legend 6" />
            <p className="legend">Legend 6</p>
          </div>
        </Carousel>
      </>
      <section className="artists-section section-padding match-section-padding" id="section_3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h2 className="mb-4">All Matches</h2>
            </div>
            <div className="col-12">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="On Going" value="1" />
                      <Tab label="UpComing" value="2" />
                      <Tab label="Past" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1"><>
                    <MatchCard setModalShow={setModalShow} modalShow={modalShow}/>
                  </></TabPanel>
                  <TabPanel value="2">Item Two</TabPanel>
                  <TabPanel value="3">Item Three</TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </section>
      <MatchHighlightModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </main>
    <Footer />
  </>
}