import { Link } from "react-router-dom"

export const NavComponent = () => {

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v2/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const options = {
            key: 'rzp_test_p8uk7FgzbApFos', // Enter the Key ID generated from the Razorpay Dashboard
            amount: '50000', // Amount is in the smallest currency unit. Hence 50000 means 50000 paise or ₹500.
            currency: 'INR',
            name: 'Your Company Name',
            description: 'Test Transaction',
            image: 'https://your-logo-url.com', // Optional, ensure this URL is correct and accessible
            order_id: 'ORD-1', // Ensure this is a valid Razorpay order ID
            handler: function (response) {
                alert('Payment ID: ' + response.razorpay_payment_id);
                alert('Order ID: ' + response.razorpay_order_id);
                alert('Signature: ' + response.razorpay_signature);
                // Here you can make an API call to your backend to verify the payment and complete the order.
            },
            prefill: {
                name: 'Customer Name',
                email: 'customer.email@example.com',
                contact: '9999999999', // Ensure this is a 10-digit number without country code
            },
            notes: {
                address: 'Customer Address',
            },
            theme: {
                color: '#3399cc', // You can customize the color theme as needed
            },
        };


        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return <>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    Turf Cricket
                </Link>
                <a onClick={() => displayRazorpay()} className="btn custom-btn d-lg-none ms-auto me-4">Buy Ticket</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav align-items-lg-center ms-auto me-lg-5">
                        <li className="nav-item">
                            <Link className="nav-link click-scroll" to={"/"}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link click-scroll" to="matches">Matches</Link>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_3">Shop</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_4">Rankings</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_5">News</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_6">Teams</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_6">Awards</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_6">Videos</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link click-scroll" to={"/login"}>Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link click-scroll" to={"/register"}>Register</Link>
                        </li>
                    </ul>
                    <a href="ticket.html" className="btn custom-btn d-lg-block d-none">Book Now</a>
                </div>
            </div>
        </nav ></>
}