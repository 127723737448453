import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";

export const FormDesign = () => {
    const route = useNavigate();

    return <>
        <div className="container-login">
            <div className="screen">
                <div className="screen__content">
                    <h5>Welcome Back</h5>
                    <form className="login">
                        <div className="login__field">
                            <i className="login__icon fas fa-user" />
                            <input type="text" className="login__input" placeholder="User name / Email" />
                        </div>
                        <div className="login__field">
                            <i className="login__icon fas fa-lock" />
                            <input type="password" className="login__input" placeholder="Password" />
                        </div>
                        <button className="button login__submit">
                            <span className="button__text">Log In Now</span>
                            <i className="button__icon fas fa-chevron-right" />
                        </button>
                    </form>
                    <div className="social-login">
                        <p style={{ color: 'white', fontSize: '14px', fontWeight: 'bold' }}>Don't have an account ?</p>
                        <Button variant="contained" onClick={() => route("/register")}>
                            Sign up
                        </Button>
                    </div>
                </div>
                <div className="screen__background">
                    <span className="screen__background__shape screen__background__shape4" />
                    <span className="screen__background__shape screen__background__shape3" />
                    <span className="screen__background__shape screen__background__shape2" />
                    <span className="screen__background__shape screen__background__shape1" />
                </div>
            </div>
        </div>
    </>
}