import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './components/Home';
import { CommonComponents } from './components/CommonComponents';
import { MatchesIndex } from './components/Matches';
import { Login } from './components/Login';
import { RegisterComponent } from './components/Register';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/matches" element={<MatchesIndex />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<RegisterComponent />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
