import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import VideoPlayer from './VideoPlayer';
import './VideoPlayer.css';
export const MatchHighlightModal = (props) => {
    return <>

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    South Africa Vs India
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <iframe width="914" height="514" src="https://www.youtube.com/embed/ThOkOfn3E-g" title="Suryakumar&#39;s 100 &amp; Kuldeep&#39;s 5-fer Mark Team India&#39;s Massive Win | SA vs IND 3rd T20I Highlights" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                <VideoPlayer
                    src="https://s3.ap-south-1.amazonaws.com/ganesh-prajapat.com/turf-assets/Suryakumar+s+100+%26+Kuldeep+s+5-fer+Mark+Team+India+s+Massive+Win+_+SA+vs+IND+3rd+T20I+Highlights.mp4"
                    poster="path_to_your_poster_image.jpg"
                />
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal></>
}