export const Footer = () => {
    return <>
        <footer className="site-footer">
            <div className="site-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <h2 className="text-white mb-lg-0">Turf Cricket</h2>
                        </div>
                        <div className="col-lg-6 col-12 d-flex justify-content-lg-end align-items-center">
                            <ul className="social-icon d-flex justify-content-lg-end">
                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-twitter" />
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-apple" />
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-instagram" />
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-youtube" />
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-pinterest" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12 mb-4 pb-2">
                        <h5 className="site-footer-title mb-3">Links</h5>
                        <ul className="site-footer-links">
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Home</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Matches</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Shop</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Rankings</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">News</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Teams</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Awards</a>
                            </li>
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Videos</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                        <h5 className="site-footer-title mb-3">Have a question?</h5>
                        <p className="text-white d-flex mb-1">
                            <a href="tel: 9588034109" className="site-footer-link">
                                +91 9588034109
                            </a>
                        </p>
                        <p className="text-white d-flex">
                            <a href="mailto:turfcricket.in@gmail.com" className="site-footer-link">
                                turfcricket.in@gmail.com
                            </a>
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-11 mb-4 mb-lg-0 mb-md-0">
                        <h5 className="site-footer-title mb-3">Location</h5>
                        <p className="text-white d-flex mt-3 mb-2">
                            36 Modi nagar, Jaipur, Rajasthan, India</p>
                        <a className="link-fx-1 color-contrast-higher mt-3" href="#">
                            <span>Our Maps</span>
                            <svg className="icon" viewBox="0 0 32 32" aria-hidden="true">
                                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                                    <circle cx={16} cy={16} r="15.5" />
                                    <line x1={10} y1={18} x2={16} y2={12} />
                                    <line x1={16} y1={12} x2={22} y2={18} />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="site-footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-12 mt-5">
                            <p className="copyright-text">Copyright © 2024 Turf Cricket Company</p>
                            <p className="copyright-text">Distributed by: <a href="https://turfcricket.in">Turfcricket</a></p>
                        </div>
                        <div className="col-lg-8 col-12 mt-lg-5">
                            <ul className="site-footer-links">
                                <li className="site-footer-link-item">
                                    <a href="#" className="site-footer-link">Terms &amp; Conditions</a>
                                </li>
                                <li className="site-footer-link-item">
                                    <a href="#" className="site-footer-link">Privacy Policy</a>
                                </li>
                                <li className="site-footer-link-item">
                                    <a href="#" className="site-footer-link">Your Feedback</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}