import React from 'react';

const VideoPlayer = ({ src, poster, width = "640", height = "360" }) => {
    return (
        <div className="video-container">
            <video
                style={{ width: '100%', height: '100%' }}
                controls
                poster={poster}
            >
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoPlayer;