import React from 'react';
import './MatchCard.css'; // Assuming you are placing styles in an external CSS file

const MatchCard = ({ setModalShow,modalShow }) => {
    return (
        <div className="match-container">
            <div className="match-header">29 JUNE, 2024</div>
            <div className="match-details">
                <div className="left">
                    <span className="t20-label">T20I</span>
                    <span>29 Jun 24 | ICC Men's T20 World Cup, 2024 - Final</span>
                    <span>Kensington Oval, Bridgetown, Barbados</span>
                </div>
                <div className="right">
                    <div className="team">
                        <span>South Africa</span> <span className="score">169/8 (20.0)</span>
                    </div>
                    <div className="team">
                        <span>India</span> <span className="score">176/7 (20.0)</span>
                    </div>
                    <div className="result">India beat South Africa by 7 runs</div>
                </div>
            </div>
            <div className="buttons">
                <a onClick={() => setModalShow(!modalShow)} className="button">Watch Highlights</a>
                <a href="#" className="button">Match Centre</a>
            </div>
        </div>
    );
}

export default MatchCard;
