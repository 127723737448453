import { useEffect, useState } from "react";

import Modal from 'react-bootstrap/Modal';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { CommonComponents } from "../CommonComponents";
import './register.css'
import { RegisterFormDesign } from "./RegisterFormDesign";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";



export const RegisterComponent = () => {
    const route = useNavigate();
    const { width, height } = useWindowSize()
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [isMobile, setIsMobile] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(true)
    const handleResize = () => {
        if (window.innerWidth <= 768) { // You can set your own breakpoint for mobile
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        // Initial check when the component mounts
        handleResize();

        // Add resize listener
        window.addEventListener('resize', handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const congratulationsMessage = () => {
        setShowLoginModal(!showLoginModal)
        Swal.fire({
            title: "Congratulations 🎉",
            text: "You are registered successfully!",
            icon: "success"
        }).then((result) => {
            if (result.isConfirmed) {
                route("/")
            }
        });
        setShowSuccessMessage(!showSuccessMessage);
    }


    return <>
        <CommonComponents />
        {isMobile ? <div className="register-content-mobile">
            <RegisterFormDesign congratulationsMessage={() => congratulationsMessage()} />
        </div> : <>
            <Modal
                backdrop="static"
                className="register-modal"
                keyboard={false}
                show={showLoginModal}
                onHide={() => setShowLoginModal(!showLoginModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <RegisterFormDesign congratulationsMessage={() => congratulationsMessage()} />
                </Modal.Body>
            </Modal>
        </>}
        {showSuccessMessage ? <Confetti
            width={width}
            height={height}
        /> : <></>}

    </>
}