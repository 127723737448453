import { CommonComponents } from '../CommonComponents';
import { Footer } from '../Footer';
import Modal from 'react-bootstrap/Modal';
import './login.css';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { FormDesign } from './formDesign';
export const Login = () => {
  const [isMobile, setIsMobile] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(true)


  const handleResize = () => {
    if (window.innerWidth <= 768) { // You can set your own breakpoint for mobile
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    // Initial check when the component mounts
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>
    <CommonComponents />
    {isMobile ? <>

      <FormDesign />
    </> : <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={showLoginModal}
        onHide={() => setShowLoginModal(!showLoginModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <FormDesign />
        </Modal.Body>
      </Modal>
    </>}

  </>
}