import { AboutSection } from "./AboutSection"
import { ArtistsSection } from "./ArtistsSection"
import { CommonComponents } from "./CommonComponents"
import { ContactSection } from "./ContactSection"
import { Footer } from "./Footer"
import { HeroSection } from "./HeroSection"
import { PricingSection } from "./PricingSection"
import { ScheduleSection } from "./ScheduleSection"

export const Home = () => {
  return <>
    <main>
      <CommonComponents />
      <HeroSection />
      <AboutSection />
      <ArtistsSection />
      <ScheduleSection />
      <PricingSection />
      <ContactSection />
    </main>
    <Footer />
  </>
}