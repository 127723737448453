export const Step1 = () => {
    return <>
        <div className="container-register">
            <h2>Personal Information</h2>
            <div className="form-group">
                <label htmlFor="fullName">Your Full Name</label>
                <input type="text" id="fullName" placeholder="Your Full Name" />
            </div>
            <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <div style={{ display: 'flex' }}>
                    <select id="countryCode" style={{ width: '30%', marginRight: '10px' }}>
                        <option value={+91}>+91</option>
                        {/* Add other country codes as needed */}
                    </select>
                    <input type="text" id="phone" placeholder={9999999999} style={{ width: '70%' }} />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" placeholder="Email Address" />
            </div>
            <div className="form-group">
                <label htmlFor="userName">User Name</label>
                <input type="text" id="userName" placeholder="User Name" />
            </div>
            <div className="form-group">
                <label htmlFor="country">Country</label>
                <select id="country">
                    <option value="India">India</option>
                    {/* Add other countries as needed */}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="state">State / Province</label>
                <select id="state">
                    <option value>Select State / Province</option>
                    {/* Add states/provinces as needed */}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="district">City</label>
                <select id="district">
                    <option value>Select City</option>
                </select>
            </div>
        </div>
    </>
}